// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyCDyK8RDWreHsFq5IR3Jh-dOh7Ih6CD1AY",
    authDomain: "clover-racing.firebaseapp.com",
    databaseURL: 'https://clover-racing-default-rtdb.firebaseio.com',
    projectId: "clover-racing",
    storageBucket: "clover-racing.appspot.com",
    messagingSenderId: "9756105197",
    appId: "1:9756105197:web:07c8ce995c89d5d8b86fe4",
    measurementId: "G-PQWQJYB61D"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
